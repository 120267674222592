<template>
  <div class="h100 vh100 d-flex d-col">
    <TopToolbar />
    <section class="header text-center center d-col">
      <h1 class="weight-300 font-48">{{ $t("home.whitepaper.whitepaper") }}</h1>
      <p>{{ $t("home.whitepaper.subtitle") }}</p>
    </section>
    <div class="grow align-center wrap col gap-50 b-box pa-10 mt-50">
      <div class="row gap-50 wrap center">
        <div class="card align-center" v-for="item in items" :key="item.idx">
          <img :src="item.image" />
          <p>{{ item.name }}</p>
          <a
            :href="item.location"
            download
            :title="tip && $t('home.whitepaper.download')"
          >
            <s-icon color="green" height="30">{{ item.icon }}</s-icon>
          </a>
          <div class="center">
            <router-link :to="item.location">
              <s-btn class="green mb-10">{{
                $t("home.whitepaper.view")
              }}</s-btn>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopToolbar from "@/components/Toolbars/TopToolbar.vue";
import { mapState } from "vuex";
export default {
  name: "Register",
  components: {
    TopToolbar,
  },
  computed: {
    items() {
      return [
        {
          image: "/img/static/logo-landing.svg",
          name: "SecurMeet",
          icon: "arrow-down-thin-circle-outline",
          location: "/pdf/learn-more.pdf",
        },
        {
          image: "/img/static/securdocs.png",
          name: "SecurDoc",
          icon: "arrow-down-thin-circle-outline",
          location: "/pdf/SecurDoc-Exec.pdf",
        },
      ];
    },
    ...mapState("user", ["tip"]),
  },
};
</script>


<style scoped>
.header {
  width: 100%;
  height: 300px;
  background-color: #24394b;
}

.card {
  border: 0;
  border-radius: 8px 8px 8px 8px;
  border-top: 8px solid;
  border-top-color: var(--green);
  box-shadow: 0px 2px 18px 0px rgb(0 0 0 / 17%);
  width: 200px;
  height: 275px;
  transition: 0.2s all ease;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: black;
  padding: 10px;
  box-sizing: border-box;
  font-size: 20px;

  color: var(--green);
}

.card:hover {
  transform: scale(1.02);
}

img {
  width: 100%;
}

p {
  font-weight: 300;
}
</style>

